import React, { useContext } from 'react'

import { useAuth } from '../hooks/use-auth.js'

import { Context } from '../stores/userStore'

// import LoadingBar from '../components/LoadingBar'

import { Typography } from '@material-ui/core'

// import { isEmpty } from 'lodash'
import Login from '../components/Login'

import logo from '../images/logo.png'

import '../App.css'

const HomePage = ({ match }) => {
  const { store } = useContext(Context)

  const [loading, setLoading] = React.useState(false)

  const auth = useAuth()

  // useEffect(() => {
  //   // console.log('I am a userClaim.')
  //   console.log('auth.userClaims.', auth.userClaims)
  // }, [auth.userClaims])

  // React.useEffect(() => {
  //   // console.log('I am a userClaim.')
  //   console.log('auth?.user.', auth?.user)
  // }, [auth?.user])

  // React.useEffect(() => {
  //   // console.log('I am a userClaim.')
  //   console.log('store', store)
  // }, [store])

  // const handleLogout = async () => {
  //   await auth.signout()
  // }

  return (
    <div className='Homepage column center'>
      {auth.user && (
        <React.Fragment>
          <Typography variant='h6' gutterBottom>
            Welcome to the VIPdeskConnect Portal {store.user.displayName}!
          </Typography>

          <img src={logo} className='logo' alt='logo' />

          <Typography variant='subtitle1' gutterBottom>
            Use the navigation icons on the left navigation bar to get find your
            way around. When you hover over the icons, the navigation bar will
            expand to show the full names of each link.
          </Typography>
        </React.Fragment>
      )}
      {!auth.user && <Login loading={loading} setLoading={setLoading}></Login>}
    </div>
  )
}

export default HomePage
