import React from 'react'

import { Icon, Button, Typography, Link } from '@material-ui/core'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className='column grow center-page' role='alert'>
      <Icon fontSize={'large'}>error</Icon>
      <Typography variant='h6' gutterBottom>
        Whoops something appears to have gone wrong... We have notified the
        development team. You can try again, and if the problem persists please
        contact us using the link below.
      </Typography>
      <Typography variant='h6' gutterBottom>
        <Link
          color='primary'
          href='mailto:devteam@vipdeskconnect.com?&subject=Test%20Sbuject&body=Body-goes-here'
        >
          Email Dev Team
        </Link>
      </Typography>

      <Typography variant='h6' gutterBottom>
        <strong>Error Message:</strong> {error.message}
      </Typography>
      <div className='spaced-medium'>
        <Button
          variant='contained'
          color='primary'
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
      </div>
    </div>
  )
}

export default ErrorFallback
