import { createContext } from 'react'

export const Context = createContext()

let initialState = {
  user: {
    userId: undefined,
    userName: '',
    userEmail: '',
    displayName: '',
    jobTitle: '',
    groups: [],
    // userPhoto: null,
    ipAddress: '',
  },
  clients: [],
  pages: [],
  officeTeams: [],
  serviceNames: [],
  teamNames: [],
  agentUserNames: [],
  zendeskInstances: [],
  // accessToken: '',
}

export const initUser = (user) => {
  initialState.user = user
}

export { initialState }

export const reducer = (currentState = initialState, action) => {
  switch (action.type) {
    case 'SET_OFFICE_TEAMS':
      return {
        ...currentState,
        officeTeams: action.payload.officeTeams,
      }
    case 'SET_PAGES':
      return {
        ...currentState,
        pages: action.payload.pages,
      }
    case 'UPDATE_USER':
      return {
        ...currentState,
        user: {
          userName: action.payload.userName.split('@', '1')[0],
          userEmail: action.payload.userEmail,
          displayName: action.payload.displayName,
          userId: action.payload.userId,
          uid: action.payload.uid,
          jobTitle: action.payload.jobTitle,
          // userPhoto: action.payload.userPhoto,
          groups: action.payload.groups,
        },
      }
    // case 'UPDATE_USER_PHOTO':
    //   return {
    //     ...currentState,
    //     user: {
    //       ...currentState.user,
    //       userPhoto: action.payload.userPhoto,
    //     },
    //   }
    case 'USER_GEO_INFORMATION':
      return {
        ...currentState,
        userGeoInformation: action.payload.userGeoInformation,
      }
    // case 'SET_TOKEN':
    //   return {
    //     ...currentState,
    //     accessToken: action.payload.accessToken,
    //   }
    case 'RESET_STORE':
      return {
        user: {
          userId: null,
          userName: '',
          userEmail: '',
          displayName: '',
          jobTitle: '',
          groups: [],
          // userPhoto: null,
        },
        // accessToken: '',
        serviceNames: [],
        teamNames: [],
        agentUserNames: [],
        zendeskInstances: [],
        clients: [],
        officeTeams: [],
      }
    case 'SET_SERVICE_NAMES':
      return {
        ...currentState,
        serviceNames: action.payload.serviceNames,
      }
    case 'SET_CLIENTS_NEW':
      return {
        ...currentState,
        clients: action.payload.clients,
      }
    case 'SET_TEAM_NAMES':
      return {
        ...currentState,
        teamNames: action.payload.teamNames,
      }
    case 'SET_AGENT_USER_NAMES':
      return {
        ...currentState,
        agentUserNames: action.payload.agentUserNames,
      }

    case 'SET_ZENDESK_INSTANCES':
      return {
        ...currentState,
        zendeskInstances: action.payload.zendeskInstances,
      }
    default:
      return currentState
  }
}
