// import React, { useContext } from 'react'

import firebase from 'firebase/app'
import 'firebase/functions'

import * as dayjs from 'dayjs'

// import { Context } from '../stores/userStore'
// import { useAuth } from '../hooks/use-auth.js'

const myErrorHandler = async (error, info) => {
  // const auth = useAuth()
  // const { store } = useContext(Context)

  let date = dayjs().format('YYYY-MM-DD HH:mm:ss')

  const errorData = {
    errorMessage: error.message,
    errorStack: error.stack,
    info,
    date: date,
  }

  console.log({ error })
  console.log({ info })

  let logErrors = firebase.functions().httpsCallable('logErrors')

  logErrors(errorData)
}

export default myErrorHandler
