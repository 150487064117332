import React from 'react'
import ReactDOM from 'react-dom'
import 'firebase/auth'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

// import { BrowserRouter as Router } from 'react-router-dom'

import 'typeface-open-sans'

import 'react-dates/lib/css/_datepicker.css'
import './react_dates_overrides.css'

let app

if (!app) {
  app = ReactDOM.render(<App />, document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
