import React, { useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Context } from '../stores/userStore'

import { Button } from '@material-ui/core'
import LogoutButton from './LogoutButton'

import { useAuth } from '../hooks/use-auth.js'

import { useHistory } from 'react-router-dom'

import blankAvatar from '../images/blank-avatar.png'

// import getMSPhoto from '../utils/getMSPhoto'

const AppBar = (className, ...props) => {
  const { store } = useContext(Context)

  const [pathName, setPathName] = useState('')

  let location = useLocation()

  let history = useHistory()

  const auth = useAuth()

  // let { from } = location.state || { from: { pathname: '/' } }
  //

  useEffect(() => {
    const getPathName = () => {
      if (auth.pages) {
        auth.pages.forEach((page) => {
          if (page.path === location.pathname) {
            setPathName(page.name)
          }
        })
      }
      if (auth.pagesPublic) {
        auth.pagesPublic.forEach((page) => {
          if (page.path === location.pathname) {
            setPathName(page.name)
          }
        })
      } else {
        setPathName('')
      }
    }
    getPathName()
  }, [location, auth.pages, auth.pagesPublic])

  // useEffect(() => {
  //   const getAvatar = async () => {
  //     if (store.accessToken) {
  //       const avatarPhoto = await getMSPhoto(store.accessToken)
  //       dispatch({
  //         type: 'UPDATE_USER_PHOTO',
  //         payload: {
  //           userPhoto: avatarPhoto,
  //         },
  //       })
  //     }
  //   }

  //   getAvatar()
  // }, [store.accessToken, dispatch])

  const handleLogin = async () => {
    try {
      await auth.signin()
    } catch (error) {
      console.log('error with login', error)
    }
  }

  const handleLogout = async () => {
    await auth.signoutFirebaseMicrosoft()
    // history.push('/login')
  }

  return (
    <div className={'AppBar row'}>
      <div
        className='spaced-small-left spaced-small-right center-page hover-effect'
        onClick={() => history.push('/')}
      >
        VIPdesk Portal
      </div>
      <div className='center-page spacer grow'>
        {/* <div className='app-bar-page-title spacer grow'> */}

        <div className='space-between flex grow'>
          <span className='spaced-small-left'>{pathName}</span>

          <span className='red'>
            {auth?.userClaims?.isEmulatedUser &&
              '*******USER EMULATION MODE ACTIVE********'}
          </span>
          <span className='red spaced-small-right'>
            {!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
              ? 'Localhost Development'
              : ''}
          </span>
        </div>
      </div>

      <div className='row'>
        {auth.user ? (
          <div className='row center-page'>
            {store?.user?.userPhoto ? (
              <img
                className='avatar'
                alt='me'
                src={store?.user?.userPhoto}
              ></img>
            ) : (
              <img className='avatar' alt='me' src={blankAvatar}></img>
            )}
            {/* {auth?.isEmulatedUser && (
              <span className='red user-name'>{store?.user.displayName}</span>
            )} */}

            {/* {!auth?.isEmulatedUser && ( */}
            <span className='user-name'>{store?.user.displayName}</span>
            {/* )} */}
            <div className='spaced-right-s'>
              <LogoutButton onClick={() => handleLogout()}></LogoutButton>
            </div>
            {/* {auth?.isEmulatedUser && (
              <div className='spaced-right-s'>
                <Button
                  variant='contained'
                  color='primary'
                  className='dense button-normal'
                  // className='button-disable-user-emulation'
                  size='small'
                  {...props}
                  onClick={auth?.disableUserEmulation}
                >
                  Disable User Emulation
                </Button>
              </div>
            )} */}
            {auth?.userClaims?.isEmulatedUser && (
              <div className='spaced-right-s spaced-medium-top-bottom'>
                <Button
                  size='small'
                  variant='contained'
                  // className='dense button-normal'
                  className='dense'
                  color='secondary'
                  onClick={auth?.disableUserEmulation}
                >
                  Disable User Emulation
                </Button>
              </div>
            )}
          </div>
        ) : (
          store.user.userId === null && (
            <div className='row center-page'>
              <div className='spaced-right-s spaced-medium-top-bottom'>
                <Button
                  size='small'
                  variant='contained'
                  className='dense button-normal'
                  color='primary'
                  onClick={() => handleLogin()}
                >
                  Login
                </Button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default AppBar
