import React from 'react'

// import LinearProgress from '@material-ui/core/LinearProgress'
import { Typography } from '@material-ui/core'

import { loadingMessages } from '../config/loadingMessages'

import { css } from '@emotion/core'
import RingLoader from 'react-spinners/RingLoader'

const override = css`
  display: flex;
  border-color: #3f51b5;
`

const getLoadingMessage = () => {
  const index = Math.floor(Math.random() * loadingMessages.length + 1)

  return loadingMessages[index]
}

const LoadingBar = () => {
  return (
    <div className='loading-circle'>
      <div className='center typography'>
        <Typography variant='h5' gutterBottom>
          {getLoadingMessage()}
        </Typography>
      </div>
      {/* <LinearProgress className='loading-bar-dark'></LinearProgress>
      <LinearProgress className='loading-bar-dark'></LinearProgress> */}
      <div className='center flex'>
        <RingLoader css={override} size={80} color={'#81c341'} loading={true} />
      </div>
    </div>
  )
}

export default LoadingBar
