import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/auth'

import { db } from '../config/firebaseconfig'
import { provider } from '../config/azureconfig'
import { Context } from '../stores/userStore'

import { useHistory } from 'react-router-dom'
import { useCollection } from 'react-firebase-hooks/firestore'
import dayjs from 'dayjs'

const authContext = createContext()

const minutesToEmulationExpiry = 15
const emulationExpiryInMs = minutesToEmulationExpiry * 60000

// Provider component for app. Children call useAuth to get access to methods and variables
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

const isLocalHost =
  process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? true : false

// const isDevelopment = process.env.REACT_APP_DOMAIN === 'bifrost' ? true : false

// console.log({ isDevelopment })

// const isProduction =
//   process.env.REACT_APP_DOMAIN === 'production' ? true : false

// console.log({ isProduction })

// console.log({ isLocalHost })

const functionEmulatorRunning = true
// const useFirestoreEmulator = true

if (isLocalHost && functionEmulatorRunning) {
  console.log(
    '** Running on localhost and with funciton emulator flag true. Firebase functions will attempt to route through funcitons emulator. **'
  )

  // if (useFirestoreEmulator) {
  //   db.settings({
  //     host: 'localhost:4000',
  //     ssl: false,
  //   })
  // }

  firebase
    .app()
    .functions() //add location here also if you're mentioning location while invoking function()
    .useEmulator('localhost', 5001)

  console.log('** Running in a development environment. **')
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  // let pagesQuery = useRef(query)

  let history = useHistory()

  const isEmulationLogoutStarted = useRef(false)

  const [emulationLogDocumentId, setEmulationLogDocumentId] = useState(null)
  const [user, setUser] = useState(null)
  const [authTime, setAuthTime] = useState(null)
  const [userClaims, setUserClaims] = useState({})
  const [pagesPublic, setPagesPublic] = useState(null)
  const [pages, setPages] = useState(null)

  const [pagesQuery, setPagesQuery] = useState(null)
  const [pagesSnapshot, loadingPages, errorPages] = useCollection(
    pagesQuery,
    null
  )

  const [pagesQueryPublic, setPagesQueryPublic] = useState(null)

  const [pagesPublicSnapshot, loadingPublicPages, errorPublicPages] =
    useCollection(pagesQueryPublic, null)

  const [clientsQuery, setClientsQuery] = useState(null)
  const [clientsSnapshot, loadingClients, errorClients] = useCollection(
    clientsQuery,
    null
  )

  const [officeTeamsQuery, setOfficeTeamsQuery] = useState(null)
  const [officeTeamsSnapshot, loadingOfficeTeams, errorOfficeTeams] =
    useCollection(officeTeamsQuery, null)

  const [userQuery, setUserQuery] = useState(null)
  const [userSnapshot, loadingUser, errorUser] = useCollection(userQuery, null)

  // const [reauthenticateConfirmed, setReauthenticateConfirmed] = useState(false)

  if (
    loadingUser ||
    loadingOfficeTeams ||
    loadingPages ||
    loadingClients ||
    loadingPublicPages ||
    errorPublicPages
  ) {
    //
  }

  if (errorOfficeTeams) {
    // console.log({ errorOfficeTeams })
  }

  if (loadingOfficeTeams) {
    // console.log({ loadingOfficeTeams })
    // console.log({ officeTeamsSnapshot })
  }

  if (errorUser || errorOfficeTeams || errorPages || errorClients) {
    //
  }

  const { dispatch } = useContext(Context)

  const resetUser = () => {
    dispatch({
      type: 'RESET_STORE',
    })

    setUser(null)
    setPages(null)
    setPagesQuery(null)
    setClientsQuery(null)
    setUserQuery(null)
    setUserClaims(null)
    setAuthTime(null)
  }

  const getPagesCollection = useCallback(
    async (userClaims = null) => {
      // console.log('getting pages collection.')
      let documents = []

      // console.log({ userClaims })

      if (userClaims && userClaims?.vipRole) {
        let vipRole = userClaims?.vipRole

        // console.log({ userClaims })
        setPagesQuery(
          db
            .collection('pages')
            .where(`access.roles`, 'array-contains-any', [vipRole])
        )
        if (pagesSnapshot) {
          pagesSnapshot.forEach(function (doc) {
            if (!doc || !doc.exists) {
              console.log('Error pages Doc does not exist!') // TODO handle error better
              return
            }
            documents.push(doc.data())
          })

          setPages(documents)
        }
      } else {
        setPagesQueryPublic(
          db
            .collection('pages')
            .where(`access.roles`, 'array-contains-any', ['public'])
        )
        if (pagesPublicSnapshot) {
          pagesPublicSnapshot.forEach(function (doc) {
            if (!doc || !doc.exists) {
              console.log('Error pages public Doc does not exist!') // TODO handle error better
              return
            }
            documents.push(doc.data())
          })

          setPagesPublic(documents)
        }
      }
    },
    [pagesSnapshot, pagesPublicSnapshot]
  )

  const getClientsCollection = useCallback(
    async (type, userClaims) => {
      let documents = []
      let collection = db.collection('clients')
      let vipRole = userClaims?.vipRole
      let vipClients = userClaims?.vipClients

      if (!vipRole || !vipClients) return

      setClientsQuery(
        collection
          .where(`access.roles.${vipRole}`, '==', true)
          .where(`access.clients`, 'array-contains-any', vipClients)
      )

      if (clientsSnapshot) {
        if (clientsSnapshot.empty) {
          console.log('Error clientsSnapshot Doc does not exist!')
        } else {
          clientsSnapshot.forEach(function (doc) {
            documents.push(doc.data())
          })

          documents.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase()
              ? 1
              : b.name.toUpperCase() > a.name.toUpperCase()
              ? -1
              : 0
          )

          dispatch({
            type,
            payload: { clients: documents },
          })

          // console.log('clients: ')
          // console.log({ documents })

          let serviceNames = []

          documents.forEach((client) => {
            if (Array.isArray(client?.brightPattern?.services)) {
              client?.brightPattern?.services.forEach((serviceName) => {
                if (
                  serviceName !== '' &&
                  serviceName !== null &&
                  serviceName !== undefined
                ) {
                  serviceNames.push(serviceName)
                }
              })
            }
          })

          serviceNames.sort()

          serviceNames.unshift('Select None')
          serviceNames.unshift('Select All')

          let category = ''

          serviceNames = serviceNames.map((serviceName) => {
            if (
              serviceName !== undefined &&
              serviceName !== null &&
              serviceName !== ''
            ) {
              category = serviceName.split(' -')[0]
              if (
                serviceName === 'Select None' ||
                serviceName === 'Select All'
              ) {
                category = null
              }
            } else {
              serviceName = ''
              category = null
            }
            return {
              text: serviceName,
              value: serviceName,
              category,
            }
          })

          dispatch({
            type: 'SET_SERVICE_NAMES',
            payload: { serviceNames },
          })

          let teams = []
          let agents = []

          documents.forEach((document) => {
            if (Array.isArray(document?.brightPattern?.teams)) {
              document?.brightPattern?.teams.forEach((team) => {
                teams.push(team?.team)
                if (Array.isArray(team?.agents)) {
                  team.agents.forEach((agent) =>
                    agents.push({
                      text: agent,
                      value: agent,
                      team_name: team.team,
                    })
                  )
                }
              })
            }
          })

          let zendesks = []
          documents.forEach((document) => {
            if (
              document &&
              Array.isArray(document.zendesk) &&
              document?.zendesk[0].subdomain !== undefined
            ) {
              document.zendesk.forEach((zendesk) => {
                if (!zendesks.includes(zendesk?.subdomain)) {
                  zendesks.push(zendesk?.subdomain)
                }
              })
            }
          })

          zendesks = zendesks.sort()
          zendesks = zendesks.map((zendesk) => {
            return {
              text: zendesk,
              value: zendesk,
            }
          })

          dispatch({
            type: 'SET_ZENDESK_INSTANCES',
            payload: { zendeskInstances: zendesks },
          })

          if (teams.length > 0) {
            teams = teams.sort()

            teams.unshift('Select None')
            teams.unshift('Select All')
            teams = teams.map((team) => {
              let category = team.split(' -')[0]
              if (team === 'Select None' || team === 'Select All') {
                category = null
              }

              return {
                text: team,
                value: team,
                category,
              }
            })

            dispatch({
              type: 'SET_TEAM_NAMES',
              payload: { teamNames: teams },
            })

            agents.sort((a, b) =>
              a.value > b.value ? 1 : b.value > a.value ? -1 : 0
            )

            agents.unshift({
              text: 'Select - None',
              value: 'Select - None',
              team_name: null,
            })
            agents.unshift({
              text: 'Select - All',
              value: 'Select - All',
              team_name: null,
            })

            dispatch({
              type: 'SET_AGENT_USER_NAMES',
              payload: {
                agentUserNames: agents,
              },
            })
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientsSnapshot]
  )

  const getOfficeTeamsCollection = useCallback(
    async (type, userClaims) => {
      // console.log('** getOfficeTeamsCollection')
      let documents = []
      let collection = db.collection('office_teams')
      let vipRole = userClaims?.vipRole
      let vipClients = userClaims?.vipClients

      if (!vipRole || !vipClients) return

      setOfficeTeamsQuery(
        collection
          .where(`access.roles.${vipRole}`, '==', true)
          .where(`access.clients`, 'array-contains-any', vipClients)
      )

      if (officeTeamsSnapshot) {
        officeTeamsSnapshot.forEach(function (doc) {
          if (!doc || !doc.exists) {
            console.log('Error office teams Doc does not exist!') // TODO handle error better
            return
          }
          let docData = doc.data()
          if (docData?.client === 'noRestriction') {
            docData.category = 'VIP Internal'
          } else {
            docData.category = 'Client'
          }
          docData.text = docData?.name
          docData.value = docData?.name

          documents.push(docData)
        })

        documents.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase()
            ? 1
            : b.name.toUpperCase() > a.name.toUpperCase()
            ? -1
            : 0
        )

        // console.log('officeTeams')
        // console.log({ documents })
        dispatch({
          type,
          payload: { officeTeams: documents },
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [officeTeamsSnapshot]
  )

  const getUserProfileFromFirebase = useCallback(
    async (user) => {
      let newUser = false
      let collection = db.collection('users')
      let userGraphProfile

      let userData = {}

      // console.log({ user })

      setUserQuery(collection.where('uid', '==', user.uid))

      // console.log({ userSnapshot })
      if (userSnapshot) {
        if (userSnapshot.size === 0) {
          console.log('No user doc match!')

          const syncUIDtoFireStore = firebase
            .functions()
            .httpsCallable('syncUIDtoFireStore')

          await syncUIDtoFireStore()
          newUser = true
          // await reauthenticate()
        } else {
          userSnapshot.forEach(function (doc) {
            if (!doc || !doc.exists) {
              console.log('Error user Doc does not exist!') // TODO handle error better
              return
            }
            userData = doc.data()
          })

          let groupsArray = Object.keys(userData.adGroups)

          userGraphProfile = {
            userId: userData.id,
            jobTitle: userData.jobTitle,
            // userPhoto: userPhoto,
            groups: [...groupsArray],
          }

          // console.log({ user })
          // console.log({ userGraphProfile })

          dispatch({
            type: 'UPDATE_USER',
            payload: {
              userEmail: user.email,
              userName: user.email,
              displayName: user.displayName,
              userId: userGraphProfile.userId,
              uid: user.uid,
              jobTitle: userGraphProfile.jobTitle,
              userPhoto: userGraphProfile.userPhoto,
              groups: groupsArray,
            },
          })
        }
      }
      return newUser
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSnapshot]
  )

  const signoutFirebase = async (cb) => {
    resetUser()
    await firebase.auth().signOut()
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      // console.log('auth state changed')
      // console.log({ user })
      if (user) {
        // console.log({ user })
        firebase
          .auth()
          .currentUser.getIdTokenResult(true)
          .then(async (idTokenResult) => {
            // console.log({ idTokenResult })

            const isEmulated = idTokenResult?.claims?.emulatedAccess || false
            const emulationTime = idTokenResult?.claims?.emulationTime || null

            if (isEmulated && emulationTime) {
              let convertedEmulationTime = dayjs(emulationTime)

              let tokenExpiryTime = convertedEmulationTime.add(
                emulationExpiryInMs,
                'ms'
              )

              let currentTime = dayjs()

              let msUntilTokenExpiry = tokenExpiryTime - currentTime
              // console.log('diff in ms', msUntilTokenExpiry)

              if (msUntilTokenExpiry > 0 && !isEmulationLogoutStarted.current) {
                console.log(
                  `Logout from emulated user in ${Math.round(
                    msUntilTokenExpiry / 1000 / 60
                  )} minutes.`
                )
                setTimeout(() => {
                  console.log('Emulation session expired, logging out...')
                  disableUserEmulation()
                }, msUntilTokenExpiry)

                isEmulationLogoutStarted.current = true
              }

              if (
                msUntilTokenExpiry <= 0 &&
                !isEmulationLogoutStarted.current
              ) {
                disableUserEmulation()
              }
            }

            let claims = {
              isEmulatedUser: isEmulated,
              vipEmployee: idTokenResult.claims.vip_employee,
              vipRole: idTokenResult.claims?.vip_role,
              vipClients: idTokenResult.claims?.vip_clients
                ? idTokenResult.claims?.vip_clients.split(',')
                : [],
            }

            await getUserProfileFromFirebase(user)

            if (!claims || claims.vipRole === undefined) {
              console.log('!claims or !claims.vipRole')

              await reauthenticate()
            }

            await getPagesCollection()
            await getPagesCollection(claims)
            await getOfficeTeamsCollection('SET_OFFICE_TEAMS', claims)
            await getClientsCollection('SET_CLIENTS_NEW', claims)

            setAuthTime(dayjs(idTokenResult?.authTime))
            setUser(user.email)
            setUserClaims(claims)
          })
      } else {
        await getPagesCollection()

        signoutFirebase(() => history.push('/'))
      }
    })

    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getClientsCollection,
    getPagesCollection,
    getUserProfileFromFirebase,
    // getOfficeTeamsCollection,
    // signoutFirebase,
  ])

  const signinWithRedirect = async () => {
    let loginSuccess = false

    loginSuccess = await firebase
      .auth()
      .signInWithRedirect(provider)
      .then(async (response) => {
        // console.log('sign in')
        // console.log({ response })
        return true
      })
      .catch((error) => {
        console.error('error in signin: ', error)
        if (error?.code === 'auth/network-request-failed') {
          // TODO show an error message
          console.log(
            'This will display a network error, check your connection.'
          )
        }
      })
    return loginSuccess
  }

  const signin = async () => {
    let loginSuccess = false

    loginSuccess = await firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (response) => {
        return true
      })
      .catch((error) => {
        console.error('error in signin: ', error)
        if (error?.code === 'auth/network-request-failed') {
          // TODO show an error message
          console.log(
            'This will display a network error, check your connection.'
          )
        }
      })
    return loginSuccess
  }

  const reauthenticate = async () => {
    let reauthenticateSucces = false

    // setReauthenticateConfirmed(false)

    reauthenticateSucces = await firebase
      .auth()
      .currentUser.reauthenticateWithRedirect(provider)
      .then(async (response) => {
        // setReauthenticateConfirmed(true)
        // console.log('Is this showing')
        return true
      })
      .catch((error) => {
        console.error('Error in reauthenticate: ', error)
      })
    // setReauthenticateConfirmed(true)
    return reauthenticateSucces
  }

  const disableUserEmulation = async () => {
    console.log({ emulationLogDocumentId })

    const updateEmulationLogWithEmulationEndTime = firebase
      .functions()
      .httpsCallable('updateEmulationLogWithEmulationEndTime')

    await updateEmulationLogWithEmulationEndTime({
      logDocumentId: emulationLogDocumentId,
    })

    await signinWithRedirect()
  }

  const signoutFirebaseMicrosoft = async (cb) => {
    resetUser()
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // let redirectQueryString = ''

        // if (isLocalHost) {
        //   redirectQueryString = ''
        // }

        // isDevelopment
        //   ? (redirectQueryString =
        //       'post_logout_redirect_uri=https%3A%2F%2Fbifrost%2Evipdesk%2Ecom')
        //   : (redirectQueryString = '')

        // https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fbifrost%2Evipdesk%2Ecom

        // isProduction
        //   ? (redirectQueryString =
        //       'post_logout_redirect_uri=https%3A%2F%2Fportal%2Evipdesk%2Ecom')
        //   : (redirectQueryString = '')

        // console.log({ redirectQueryString })

        // const microsoftLogoutURL = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?${redirectQueryString}`
        const microsoftLogoutURL = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?`
        // const microsoftLogoutURL = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fbifrost%2Evipdesk%2Ecom`

        // console.log({ microsoftLogoutURL })

        // setTimeout(function () {
        // history.push(
        //   'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fbifrost%2Evipdesk%2Ecom'
        // )
        window.location.href = microsoftLogoutURL

        // }, 10000)
        // setTimeout(function () {
        //   // history.push(
        //   //   'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fbifrost%2Evipdesk%2Ecom'
        //   // )
        //   window.location.href = microsoftLogoutURL
        // }, 10000)
      })
  }

  const emulateUser = async (token) => {
    let cutomTokenSignInResult = await firebase
      .auth()
      .signInWithCustomToken(token)

    return cutomTokenSignInResult
  }

  // Return the user object and auth methods
  return {
    user,
    signin,
    reauthenticate,
    signoutFirebase,
    signoutFirebaseMicrosoft,
    userClaims,
    pages,
    getUserProfileFromFirebase,
    loadingPages,
    errorPages,
    pagesSnapshot,
    loadingClients,
    errorClients,
    clientsSnapshot,
    loadingUser,
    errorUser,
    userSnapshot,
    loadingPublicPages,
    errorPublicPages,
    pagesPublic,
    authTime,
    emulateUser,
    disableUserEmulation,
    isEmulationLogoutStarted,
    signinWithRedirect,
    setEmulationLogDocumentId,
    emulationLogDocumentId,
    // reauthenticateConfirmed,
  }
}
