import React, { useState } from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import Icon from '@material-ui/core/Icon'

import { Link } from 'react-router-dom'

// import { Context } from '../stores/userStore'

import { isEmpty } from 'lodash'

import { useAuth } from '../hooks/use-auth.js'

const NavBar = () => {
  const auth = useAuth()

  // const { store } = useContext(Context)

  const [navBarOpen, setNavBarOpen] = useState(false)

  const handleOpen = (val) => {
    setNavBarOpen(val)
  }

  return (
    <div
      className={`NavBar ${navBarOpen ? 'navBarOpen' : ''}`}
      onClick={() => handleOpen(false)}
      onMouseEnter={() => handleOpen(true)}
      onMouseLeave={() => handleOpen(false)}
    >
      <List>
        {Array.isArray(auth?.pagesPublic) &&
          auth?.pagesPublic
            .sort((a, b) => {
              return a.weight - b.weight
            })
            .filter(
              (page) => page?.disabled === false && page?.hiddenNav !== true
            )
            .map((page) => (
              <ListItem
                key={page.name}
                button
                component={Link}
                to={page.path}
                dense
                // divider
              >
                <ListItemIcon>
                  <Icon>{page.icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={page.name} />
              </ListItem>
            ))}
        {!isEmpty(auth?.pages) &&
          auth?.pages
            .sort((a, b) => {
              return a.weight - b.weight
            })
            .filter((page) => {
              return page?.disabled === false && page?.hiddenNav !== true
            })
            .map((page) => (
              <ListItem
                key={page.name}
                button
                dense
                // divider
                component={Link}
                to={page.path}
              >
                <ListItemIcon>
                  <Icon>{page.icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={page.name} />
              </ListItem>
            ))}
      </List>
    </div>
  )
}

export default NavBar
