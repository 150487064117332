import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyD9M98tGVIobi8qr2YiAtQJgrRWapngxbk',
  authDomain: 'portal.vipdesk.com',
  databaseURL: 'https://portal-vipdesk.firebaseio.com',
  projectId: 'portal-vipdesk',
  // storageBucket: 'portal-vipdesk.appspot.com',
  messagingSenderId: '849368188617',
  appId: '1:849368188617:web:12893eaa9bb1fb591b2f18',
  measurementId: 'G-ZF4M2WCCM5',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()

export { firebase, db, firebaseConfig }
