import React, { useReducer, Suspense, lazy } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import LoginPage from './pages/LoginPage'
import HomePage from './pages/HomePage'

// import AccountServices from './pages/AccountServices'
// import Admin from './pages/Admin'
// import TechTime from './pages/TechTime'
// import Profile from './pages/Profile'
// import Dashboards from './pages/Dashboards'
// import AdminPhone from './pages/AdminPhone'
// import EventLogs from './pages/EventLogs'
// import SpeedTest from './pages/SpeedTest'
// import UserAccounts from './pages/UserAccounts'
// import Unauthorized from './pages/Unauthorized'
// import SendSMS from './pages/SendSMS'
// import ScheduledReports from './pages/ScheduledReports'
// import LiveDashboardPage from './pages/LiveDashboardPage'
// import NetworkStatus from './pages/NetworkStatus'

import NavBar from './components/NavBar'
import AppBar from './components/AppBar'
import LoadingBar from './components/LoadingBar'

import ErrorFallback from './components/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'
import myErrorHandler from './utils/myErrorHandler'

import PrivateRoute from './routes/PrivateRoute'

import { Context, initialState, reducer } from './stores/userStore'
import { ProvideAuth } from './hooks/use-auth'

// eslint-disable-next-line

const LiveDashboardPage = lazy(() => import('./pages/LiveDashboardPage'))
const NetworkStatus = lazy(() => import('./pages/NetworkStatus'))
const AccountServices = lazy(() => import('./pages/AccountServices'))
const Admin = lazy(() => import('./pages/Admin'))
const TechTime = lazy(() => import('./pages/TechTime'))
const Profile = lazy(() => import('./pages/Profile'))
const Dashboards = lazy(() => import('./pages/Dashboards'))
const AdminPhone = lazy(() => import('./pages/AdminPhone'))
const EventLogs = lazy(() => import('./pages/EventLogs'))
const SpeedTest = lazy(() => import('./pages/SpeedTest'))
const UserAccounts = lazy(() => import('./pages/UserAccounts'))
const Unauthorized = lazy(() => import('./pages/Unauthorized'))
const SendSMS = lazy(() => import('./pages/SendSMS'))
const ScheduledReports = lazy(() => import('./pages/ScheduledReports'))
const UnlockAccount = lazy(() =>
  import('./components/AccountServices/UnlockAccount')
)
const UpdateAccountInformation = lazy(() =>
  import('./components/AccountServices/UpdateAccountInformation')
)
const ResetPassword = lazy(() =>
  import('./components/AccountServices/ResetPassword')
)

const App = () => {
  const [store, dispatch] = useReducer(reducer, initialState)
  const [error, setError] = React.useState(null)

  const NotFound = () => <div className='spaced-medium'>404 Page Not Found</div>

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={myErrorHandler}
      onReset={() => setError(false)}
      resetKeys={[error]}
    >
      <Context.Provider value={{ store, dispatch }}>
        <ProvideAuth>
          <Router>
            <Suspense fallback={<LoadingBar />}>
              {/* <MuiThemeProvider theme={theme}> */}
              <div className='app column grow flex'>
                <AppBar className='row'></AppBar>

                <main className='row grow flex'>
                  <nav className='column flex'>
                    <NavBar></NavBar>
                  </nav>

                  <div className='content column grow flex'>
                    <Switch>
                      {/******** Public Routes ********/}
                      <Route exact path='/login'>
                        <LoginPage />
                      </Route>

                      <Route exact path='/unauthorized'>
                        <Unauthorized />
                      </Route>

                      <PrivateRoute exact path='/'>
                        <HomePage />
                      </PrivateRoute>

                      <PrivateRoute exact path='/speed-test'>
                        <SpeedTest />
                      </PrivateRoute>

                      <PrivateRoute exact path='/account-services'>
                        <AccountServices />
                      </PrivateRoute>

                      <PrivateRoute
                        exact
                        path='/account-services/update-account'
                      >
                        <UpdateAccountInformation />
                      </PrivateRoute>

                      <PrivateRoute
                        exact
                        path='/account-services/unlock-account'
                      >
                        <UnlockAccount />
                      </PrivateRoute>

                      <PrivateRoute
                        exact
                        path='/account-services/reset-password'
                      >
                        <ResetPassword />
                      </PrivateRoute>

                      {/********  Private Routes ********/}

                      <PrivateRoute exact path='/tech-time'>
                        <TechTime />
                      </PrivateRoute>

                      <PrivateRoute exact path='/profile'>
                        <Profile />
                      </PrivateRoute>

                      <PrivateRoute path='/dashboards'>
                        <Dashboards />
                      </PrivateRoute>

                      <PrivateRoute path='/live-dashboard'>
                        <LiveDashboardPage />
                      </PrivateRoute>

                      <PrivateRoute exact path='/admin'>
                        <Admin />
                      </PrivateRoute>

                      <PrivateRoute exact path='/admin-phone'>
                        <AdminPhone />
                      </PrivateRoute>

                      <PrivateRoute exact path='/event-logs'>
                        <EventLogs />
                      </PrivateRoute>

                      <PrivateRoute exact path='/user-accounts'>
                        <UserAccounts />
                      </PrivateRoute>

                      <PrivateRoute exact path='/network-status'>
                        <NetworkStatus />
                      </PrivateRoute>

                      <PrivateRoute exact path='/send-sms'>
                        <SendSMS />
                      </PrivateRoute>

                      <PrivateRoute exact path='/scheduled-reports'>
                        <ScheduledReports />
                      </PrivateRoute>

                      <NotFound />
                    </Switch>
                  </div>
                </main>
              </div>
            </Suspense>
          </Router>
        </ProvideAuth>
      </Context.Provider>
    </ErrorBoundary>
  )
}

export default App
