export const loadingMessages = [
  'Initiating Warp Drive. Please Wait...',
  'Starting Flux Capacitors. Please Wait...',
  'Starting Engines. Please Wait...',
  'Calculating Value of Pi. Please Wait...',
  'Checking Stockroom. Please wait...',
  'Dividing by Zero. Please wait...',
  'Warming-Up Cold Fusion Generators. Please wait...',
  'Re-Inventing the Wheel. Please wait...',
  'Connecting to Satellites. Please wait...',
  'Discovering the Atom. Please wait...',
  'Searching for Dark Matter. Please wait...',
  'Opening Worm Hole. Please wait...',
]
