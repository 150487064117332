import { firebase } from '../config/firebaseconfig'

const azureConfig = {
  tenant: '4054b496-d6ce-49ae-a59a-b9508270f922',
}

const provider = new firebase.auth.OAuthProvider('microsoft.com')

provider.setCustomParameters({
  tenant: azureConfig.tenant,
})

export { provider, azureConfig }
