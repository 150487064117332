import React from 'react'

import { Button } from '@material-ui/core'

import { useAuth } from '../hooks/use-auth.js'

const LogoutButton = ({ ...props }) => {
  const auth = useAuth()

  const handleLogout = async () => {
    await auth.signoutFirebaseMicrosoft()
  }

  return (
    <Button
      variant='contained'
      color='primary'
      className='button-normal'
      size='small'
      {...props}
      onClick={() => handleLogout()}
    >
      Logout
    </Button>
  )
}

export default LogoutButton
