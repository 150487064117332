import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useAuth } from '../hooks/use-auth.js'

import LoadingBar from '../components/LoadingBar'

// A wrapper for <Route> that redirects based on various scenarios of authentication
function PrivateRoute({ children, path, location, ...rest }, props) {
  const auth = useAuth()

  const checkProtectedAuth = React.useCallback(
    (location) => {
      if (!Array.isArray(auth.pages)) return false
      let splitPathExists = false
      let splitPath = location.pathname.split('/')
      if (splitPath.length > 2) {
        splitPath = '/' + splitPath[1]
        splitPathExists = true
      }

      if (splitPathExists) location.pathname = splitPath

      return (
        auth.pages.filter((page) => page.path === location.pathname).length > 0
      )
    },
    [auth.pages]
  )

  const checkPublicAuth = React.useCallback(
    (location) => {
      if (!Array.isArray(auth.pagesPublic)) return false
      let splitPathExists = false
      let splitPath = location.pathname.split('/')
      if (splitPath.length > 2) {
        splitPath = '/' + splitPath[1]
        splitPathExists = true
      }

      if (splitPathExists) location.pathname = splitPath

      let filterCheck =
        auth.pagesPublic.filter((page) => page.path === location.pathname)
          .length > 0
      return filterCheck
    },
    [auth.pagesPublic]
  )

  const checkAuthInit = React.useCallback(() => {
    let check = false
    auth.user === undefined ? (check = false) : (check = true)

    return check
  }, [auth.user])

  const checkProtectedPagesInit = React.useCallback(() => {
    let check = false
    auth.pages === null ? (check = false) : (check = true)

    return check
  }, [auth.pages])

  const checkPublicPagesInit = React.useCallback(() => {
    let check = false
    auth.pagesPublic === null ? (check = false) : (check = true)

    return check
  }, [auth.pagesPublic])

  // 1. Check if auth is not init
  //   a) show LoadingBar
  if (!checkAuthInit()) {
    return (
      <Route {...rest} render={({ location }) => <LoadingBar></LoadingBar>} />
    )
  }

  // 2. If Auth is init && user is not logged in && public page is not authorized in Public pages && public pages is init
  //   a) show Login page
  else if (
    checkAuthInit() &&
    !auth.user &&
    checkPublicPagesInit() &&
    !checkPublicAuth(location)
    // location.pathname === 'unauthorized'
  ) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )}
      />
    )
  }

  // 3. If Auth is init && user is not logged in && page is authorized in public pages && public pages is init
  //   a) show children
  else if (
    checkAuthInit() &&
    !auth.user &&
    checkPublicPagesInit() &&
    checkPublicAuth(location)
  ) {
    return <Route {...rest} render={({ location }) => children} />
  }

  // 4. If auth is init && user is loggedIn &&  private pages is init && public pages init && private page is not authorized &&  public pages is not auth
  //   a) Show Unauthorized page
  else if (
    checkAuthInit() &&
    auth.user &&
    checkProtectedPagesInit() &&
    checkPublicPagesInit() &&
    !checkProtectedAuth(location) &&
    !checkPublicAuth(location)
    // && location.pathname !== '/unauthorized'
  ) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/unauthorized',
              state: { from: location },
            }}
          />
        )}
      />
    )
  }

  // 5. If auth is init & user is logged in && private pages is init & public pages is init & page is auth || public page is auth
  //   a) show children
  else if (
    checkAuthInit() &&
    auth.user &&
    checkProtectedPagesInit() &&
    checkPublicPagesInit() &&
    (checkProtectedAuth(location) || checkPublicAuth(location))
  ) {
    return <Route {...rest} render={({ location }) => children} />
    // 6 . If none of the scenarios above have matched, then show the loading bar
  } else {
    return (
      <Route {...rest} render={({ location }) => <LoadingBar></LoadingBar>} />
    )
  }
}

export default PrivateRoute
